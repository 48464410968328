<!--
 * @Author: your name
 * @Date: 2021-08-01 13:21:04
 * @LastEditTime: 2021-08-24 15:26:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\userCenter\index.vue
-->
<template>
  <div class="record">
    <div class="main">
      <div class="table-header flex-h flex-hc">
        <div class="item">
          <h1>{{joinActivityNum}}</h1>
          <h2>参加活动</h2>
        </div>
        <div class="item">
          <h1>{{longTime}}</h1>
          <h2>累计服务时长</h2>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tableData" size="small"  height="500">
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          label="活动名称"
          align="center"
          prop="activityName"
          width="150"
        ></el-table-column>
        <el-table-column
          label="活动开始时间"
          align="center"
         
          width="140"
        >
         <template slot-scope="scope">
           {{$formatDate(scope.row.startTime)}}
         </template>
        </el-table-column>
        <el-table-column
          label="活动结束时间"
          align="center"
          
          width="140"
        >
         <template slot-scope="scope">
           {{$formatDate(scope.row.endTime)}}
         </template>
        </el-table-column>
        <el-table-column
          label="活动服务时长（H）"
          align="center"
          prop="longTime"
        ></el-table-column>

        <el-table-column
          label="活动状态"
          align="center"
          prop="data5"
        >
         <template slot-scope="scope">
            <span v-if="scope.row.status==0">即将开始</span>
            <span v-if="scope.row.status==1">正在进行</span>
            <span v-if="scope.row.status==2">已结束</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="getImg(scope.row.activityId)" type="text" size="small"
              >查看活动照片</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="Pagination flex-h flex-vc">
        <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="sizes,total, prev, pager, next"
        :total="total"
      ></el-pagination> -->
        <el-pagination
          size="small"
          background
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-size="limit"
          layout=" prev, pager,  next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
     <el-dialog
      title="预览"
      :visible.sync="centerDialogVisible"
      width="800px"
      :modal-append-to-body="false"
      :append-to-body="true"
      center
    >
      <div class="imgBox">
        <img v-for="(item,index) in imgList" :key="index"
          :src="item"
          style="display: block; max-width: 100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
 import {  activityLogs,activityImgs,volunteer } from "@/api";
export default {
  data() {
    return {
      joinActivityNum:0,
      longTime:0,
      centerDialogVisible: false,
      tableData: [],
      limit: 10,
      total: 0,
      currPage: 1,
      imgList:[]
    };
  },
  components: {},
  methods: {
   volunteer(){
      volunteer().then(res=>{
        this.joinActivityNum=res.data.joinActivityNum;
        this.longTime=res.data.longTime;
      })
    },

    getList() {
        activityLogs({
          currPage: this.currPage,
          pageLimit: this.limit,
         
         
        }).then((res) => {
          console.log(res);
          this.tableData = res.data.list;
          this.total = res.data.totalCount;
        });
    },
    getImg(id) {
      activityImgs({
        id
      }).then((res) => {
        console.log(res);
        this.imgList=res.data
      this.centerDialogVisible=true;
      });
    },
    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
  },
  created() {
    this.getList();
    this.volunteer()
  },
};
</script>

<style lang="scss" scoped>
.record {
    padding-top: 20px;
  .main {
    width: 822px;
    height: 642px;
    background: #fff6f6;
    border-radius: 23px;
    margin: 0 auto;
    padding-top: 17px;
    box-sizing: border-box;
    padding: 0 20px;
    .table-header {
      .item {
          margin: 15px 80px;
        h1 {
          text-align: center;
          font-size: 28px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #000000;
        }
        h2 {
          text-align: center;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #848181;
        }
      }
    }
  }
}
</style>